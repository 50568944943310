
import browser from '../browser';

/**
 * A global module for accessing information about different feature flags state.
 */
class FeatureFlags {
    /**
     * Configures the module.
     *
     * @param {object} flags - The feature flags.
     * @param {boolean=} flags.runInLiteMode - Enables lite mode for testing to disable media decoding.
     * @param {boolean=} flags.ssrcRewritingEnabled - Use SSRC rewriting. Requires sourceNameSignaling to be enabled.
     * @param {boolean=} flags.enableJoinAsVisitor - Enable joining as a visitor.
     */
    init(flags) {
        this._runInLiteMode = Boolean(flags.runInLiteMode);
        this._ssrcRewriting = flags.ssrcRewritingEnabled ?? true;
        this._joinAsVisitor = Boolean(flags.enableJoinAsVisitor ?? true);
    }

    /**
     * Checks if the run in lite mode is enabled.
     * This will cause any media to be received and not decoded. (Insertable streams are used to discard
     * all media before it is decoded). This can be used for various test scenarios.
     *
     * @returns {boolean}
     */
    isRunInLiteModeEnabled() {
        return this._runInLiteMode && browser.supportsInsertableStreams();
    }

    /**
     * Checks if the clients supports re-writing of the SSRCs on the media streams by the bridge.
     * @returns {boolean}
     */
    isSsrcRewritingSupported() {
        return this._ssrcRewriting;
    }

    /**
     * Checks if the clients supports joining as a visitor.
     * @returns {boolean}
     */
    isJoinAsVisitorSupported() {
        return this._joinAsVisitor;
    }
}

export default new FeatureFlags();
